<template>
    <section class="page-attention">
        <div class="row-space-tbf">
            <div class="space-left"></div>
            
            <div class="content">
                <div class="icon" :class="[{mobile: $resize && $mq.below(780)}]">
                    <img src="/build/flappy/bird_confetti.png">
                </div>
                <template v-if="loaded">
                    <div class="title">
                        {{ $t('add_meeting.thank_you_answer') }}
                    </div>
                    <div class="description">
                        <span v-if="$route.query.response == 'yes'">
                            {{ $t('add_meeting.answer_yes', {name: meeting.name, date: moment(meeting.date, 'YYYY-MM-DD').format('D MMMM YYYY'), start_time: meeting.start_time, end_time: meeting.end_time}) }}
                        </span>
                        <span v-else-if="$route.query.response == 'no'">
                            {{ $t('add_meeting.answer_no', {name: meeting.name, date: moment(meeting.date, 'YYYY-MM-DD').format('D MMMM YYYY'), start_time: meeting.start_time, end_time: meeting.end_time}) }}
                        </span>
                         <span v-else-if="$route.query.response == 'maybe'">
                            {{ $t('add_meeting.answer_maybe', {name: meeting.name, date: moment(meeting.date, 'YYYY-MM-DD').format('D MMMM YYYY'), start_time: meeting.start_time, end_time: meeting.end_time}) }}
                        </span>
                    </div>
                </template>
                <div class="loader-spin" v-else></div>
            </div>

            <div class="space-right"></div>
        </div>
    </section>
</template>

<script>

    export default {
        data(){
            return{
                meeting: {
                    date: '',
                    start_time: '',
                    end_time: '',
                    name: ''
                },
                loaded: false
            }
        },
        mounted(){
            this.getMeetingInfo();
        },
        components: {

        },
        methods: {
            handleAnimationLogo(anim){
                this.anim_logo = anim;
                this.anim_logo.setSpeed(1);
            },
            getMeetingInfo(){
                axios.get(`/meetings/${this.$route.params.id}/response-info`)
                .then(({data}) => {
                    this.meeting = data.data
                    this.postResponse();
                    this.loaded = true
                })
            },
            postResponse(){
                axios.post(`/meetings/${this.$route.params.id}/response`, { token: this.$route.params.uuid, response: this.$route.query.response, invite_type: this.$route.query.type })
            }
        }
    }
</script>

<style lang="scss" scoped>
.icon{
    img{
        width: 400px;
    }
}
</style>